<div *ngIf="viewState.shouldDisplayEmptyState; else hasStores" class="empty-stores">
  <img src="assets/img/stores/empty-stores.svg" alt="Empty stores page" />
  <p class="heading2--bold">{{ trans('STORES.NO_STORES_TITLE') }}</p>
  <p class="body2--regular">{{ trans('STORES.NO_STORES_BODY') }}</p>
</div>

<ng-template #hasStores>
  <app-old-stores *ngIf="!revampedStoresEnabled; else revamped"></app-old-stores>
  <!-- <app-old-stores></app-old-stores> -->
  <ng-template #revamped>
    <!-- <ng-template #revamped> -->
    <div>
      <div class="stores">
        <div class="stores__header">
          <p class="stores__title heading2--bold">
            {{ trans('STORES.TITLE') }}
          </p>
          <div class="stores__description">
            <img [src]="selectedCountry.flag" alt="country flag" />
            <p class="stores__header__text body2--medium">
              {{
                'STORES.CONNECT_DESCRIPTION'
                  | translate : { selectedCountryName: selectedCountry.name }
              }}
            </p>
          </div>
        </div>
        <div class="stores__body">
          <div class="stores__tabs">
            <!-- Store Navigation Tabs -->
            <div *ngIf="viewState.userStores.length > 0" class="stores__navigation">
              <div class="stores__sidebar">
                <ng-container *ngFor="let store of viewState.userStores">
                  <button
                    class="stores__sidebar__button"
                    *ngIf="storeIsEnabled(store)"
                    (click)="onStoreSelected(store)"
                    [ngClass]="{
                      'stores__sidebar__button--selected': viewState.selectedStore === store
                    }"
                  >
                    <img loading="lazy" class="sidebar__button__icon" [src]="store.icon" alt="" />
                    <p class="sidebar__button-text body2--regular">{{ trans(store.name) }}</p>
                  </button>
                </ng-container>
              </div>
            </div>

            <!-- Store Tabs Content -->
            <div class="stores__content" *ngIf="viewState.selectedStore">
              <div class="stores__content__container">
                <div class="stores__container">
                  <div class="store__content">
                    <img
                      [src]="viewState.selectedStore.icon"
                      alt="viewState.selectedStore.name"
                      class="store__logo"
                    />
                    <p class="store__name">{{ trans(viewState.selectedStore.name) }}</p>
                    <ng-container *ngIf="storeUrlShouldBeDisplayed()">
                      <a class="store__link" [href]="storeUrl()" target="_blank">
                        <img src="assets/img/stores/store-link.svg" alt="Store Link" />
                      </a>
                    </ng-container>
                    <span class="store__state__linked" *ngIf="isStoreLinked()">
                      {{ trans('STORES.STORE__LINKED') }}
                    </span>
                    <span class="store__state__unlinked" *ngIf="!isStoreLinked()">
                      {{ trans('STORES.STORE__UNLINKED') }}
                    </span>
                  </div>

                  <div class="store__actions">
                    <ng-container *ngIf="viewState.selectedStore.howToLinkVideo">
                      <button
                        class="store__actions__video__btn"
                        (click)="displayHowToConnectStore()"
                        *ngIf="viewState.selectedStore?.howToLinkVideo"
                      >
                        <img src="assets/img/stores/store-video-play.svg" alt="Store Video Play" />
                        {{ trans('STORES.HOW_TO_LINK') }}
                      </button>
                    </ng-container>

                    <ng-container *ngIf="!this.currentStoreHasAPIKey()">
                      <button
                        class="store__actions__link__btn"
                        (click)="openStoreIntegrationModal()"
                        *ngIf="shouldConnectToStore()"
                        [disabled]="shouldDisableStoreConnection()"
                      >
                        {{
                          isDisplayingDukanStore()
                            ? ('STORES.CREATE_STORE' | translate)
                            : ('STORES.CONNECT_STORE' | translate)
                        }}
                      </button>
                    </ng-container>

                    <button
                      class="store__actions__link__btn"
                      (click)="disconnectStore()"
                      *ngIf="
                        viewState.currentCountryLinkedStore?.provider ===
                        viewState.selectedStore.provider
                      "
                    >
                      {{ 'STORES.UNLINK' | translate }}
                    </button>

                    <button
                      class="store__actions__link__btn"
                      (click)="reactiveDukanStore()"
                      *ngIf="dukanStoreIsLinkedAndInActive()"
                    >
                      {{ 'STORES.REACTIVE_STORE' | translate }}
                    </button>

                    <button
                      [routerLink]="appURLs.EDIT_DUKAN_URL"
                      class="store__actions__video__btn"
                      *ngIf="dukanShopIsActive()"
                    >
                      <img src="assets/img/stores/settings.svg" alt="Store Settings" />
                      {{ trans('STORES.DUKAN.DUKAN_SETTINGS') }}
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="viewState.isAPIKeysIntegrationEnabled && this.currentStoreHasAPIKey()"
                  class="api__key__integration"
                >
                  <app-api-keys-integration [newUI]="true"></app-api-keys-integration>
                </div>
              </div>

              <!-- WooCommerce Store Settings -->
              <ng-container
                *ngIf="
                  viewState.selectedStore.provider === 'WOOCOMMERCE' &&
                  viewState.currentCountryLinkedStore?.provider === 'WOOCOMMERCE' &&
                  this.wooCommerceStoreSettingsEnabled
                "
              >
                <div class="woocommerce__store__settings">
                  <h2 class="woocommerce__store__settings__title">
                    {{ trans('STORES.WOOCOMMERCE.STORE_SETTINGS') }}
                  </h2>
                  <div class="woocommerce__store__settings__content">
                    <img src="assets/img/stores/hierarchy.svg" alt="hierarchy icon" />
                    <div class="woocommerce__store__settings__text">
                      <p class="woocommerce__store__settings__subtitle">
                        {{ trans('STORES.WOOCOMMERCE.STORE_SETTINGS_SUBTITLE') }}
                      </p>
                      <p class="woocommerce__store__settings__description">
                        {{ trans('STORES.WOOCOMMERCE.STORE_SETTINGS_DESCRIPTION') }}
                      </p>
                    </div>
                  </div>

                  <form
                    [formGroup]="selectCategoriesForm"
                    (ngSubmit)="onSubmit()"
                    class="categories__form"
                  >
                    <mat-form-field appearance="outline" class="categories__dropdown">
                      <mat-select
                        formControlName="selectCategory"
                        placeholder="{{ trans('STORES.WOOCOMMERCE.SELECT_PLACEHOLDER') }}"
                      >
                        <mat-option *ngFor="let category of categories" [value]="category.key">
                          {{ category.value.name.arabicName }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix>expand_more</mat-icon>
                    </mat-form-field>

                    <button
                      class="submit"
                      *ngIf="selectCategoriesForm.get('selectCategory')?.value"
                    >
                      {{ trans('STORES.WOOCOMMERCE.SUBMIT') }}
                    </button>
                  </form>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Store Learning -->
          <div class="stores__learning">
            <!-- Store Courses -->
            <div class="store__courses" *ngIf="viewState.currentStoreCourse.title">
              <h3 class="store__learning__header">
                <img src="assets/img/stores/store-learning-header-icon.svg" alt="Store Learning" />
                {{ trans('STORES.STORE_LEARNING_TITLE') }}
              </h3>
              <div class="store__course__accordion">
                <div class="accordion__item">
                  <div
                    class="accordion__title"
                    (click)="toggleCourseAccordion(0)"
                    [class.active]="activeCourseIndex === 0"
                  >
                    <div class="course__title">
                      <img
                        src="assets/img/stores/store-course-title-icon.svg"
                        alt="Store Course Icon"
                      />
                      {{ trans(viewState.currentStoreCourse.title) }}
                    </div>
                    <img
                      src="assets/img/stores/arrow-down.svg"
                      alt="Accordion Arrow"
                      class="arrow"
                    />
                  </div>
                  <div
                    class="accordion__content"
                    #accordionCourseContent
                    [ngClass]="{
                      expanded: activeCourseIndex === 0,
                      collapsed: activeCourseIndex !== 0
                    }"
                    *ngFor="let item of viewState.currentStoreCourse.course_content; let j = index"
                  >
                    <a class="accordion__content__link" [href]="item.course_link" target="_blank">
                      <img src="assets/img/stores/store-video-play.svg" alt="Store Video Play" />
                      {{ trans(item.course_title) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Store FAQs -->
            <div class="store__faqs">
              <div class="stores__faqs__header">
                <img src="assets/img/stores/message-question.svg" alt="Store FAQs" />
                <h3>
                  {{ trans('STORES.STORES_FAQS_TITLE') }}
                </h3>
              </div>
              <div class="stores__faqs__accordion">
                <div
                  class="accordion__item"
                  *ngFor="let item of presenter.questions; let i = index"
                >
                  <div
                    class="accordion__title"
                    (click)="toggleQuestionAccordion(i)"
                    [class.active]="activeQuestionIndex === i"
                  >
                    {{ trans(item.title) }}
                    <img src="assets/img/stores/arrow-down.svg" alt="Accordion Arrow" />
                  </div>
                  <div
                    class="accordion__content"
                    #accordionQuestionsContent
                    [ngClass]="{
                      expanded: activeQuestionIndex === i,
                      collapsed: activeQuestionIndex !== i
                    }"
                  >
                    {{ trans(item.content) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
