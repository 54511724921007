import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { country, CountryService } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { queryString } from '@presentation/shared/router';
import { BaseCategoryModel } from 'app/core/domain/commercial-categories.model';
import { StoreModel } from 'app/core/domain/store.model';
import { UserStoreModel } from 'app/core/domain/user-store.model';
import { LogMixpanelEventUseCase } from 'app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCommercialCategoriesUseCase } from 'app/core/usecases/get-commercial-categories-usecase';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { getStoreRedirectUri } from 'app/data/repositories/merchant-store/utilities/get-store-redirect-uri.utility';
import {
  DUKAN_PROVIDER_NAME,
  EASY_ORDERS_PROVIDER_NAME,
  STORES_REVAMP_USER_FEATURE,
  WOOCOMMERCE_PROVIDER_NAME,
  WOO_COMMERCE_STORE_IMPORT_USER_FEATURE,
  YOUCAN_PROVIDER_NAME,
  ZID_PROVIDER_NAME,
} from 'app/presentation/shared/constants';
import { TreeNode } from 'app/presentation/shared/utilities/tree.utility';
import { UnlinkStorePopUpComponent } from 'app/presentation/stores/dukan/unlink-store-pop-up/unlink-store-pop-up.component';
import { YoucanCheckoutPopUpComponent } from 'app/presentation/stores/dukan/youcan-checkout-pop-up/youcan-checkout-pop-up.component';
import { StoresPresenter } from 'app/presentation/stores/presenter/stores.presenter';
import { StoresViewEvents } from 'app/presentation/stores/presenter/stores.view-events';
import {
  NewStoreModel,
  StoresViewState,
} from 'app/presentation/stores/presenter/stores.view-state';
import { WooCommerceFailureModalComponent } from 'app/presentation/stores/woo-commerce-failure-modal/woo-commerce-failure-modal.component';
import { WooCommerceModalComponent } from 'app/presentation/stores/woo-commerce-modal/woo-commerce-modal.component';
import { WooCommerceSuccessModalComponent } from 'app/presentation/stores/woo-commerce-success-modal/woo-commerce-success-modal.component';
import { YoucanLinkVideoComponent } from 'app/presentation/stores/youcan-link-video/youcan-link-video.component';
import { finalize } from 'rxjs';
import { parseError } from '../../../v3/presentation/shared/error';
import { toastError } from '../../../v3/presentation/shared/toast';
import { DukanShopModel, StateStatuses } from '../../core/domain/dukan-shop/dukan-shop.model';
import { ReactivateDukanShopUseCase } from '../../core/usecases/dukan-shop/reactivate-dukan.usecase';
import { LinkMerchantStoreUseCase } from '../../core/usecases/merchant-store/link-merchant-store.usecase';
import { appUrlsConstantsInjectionToken } from '../../data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from '../base/base.component';
import { REVAMPED_STORES } from '../shared/constants/feature-flags';
import { ApiKeysIntegrationComponent } from './api-keys-integration/api-keys-integration.component';
import { DukanSuccessfullyCreatedComponent } from './dukan/dukan-successfully-created/dukan-successfully-created.component';
import { SetupYourDukanComponent } from './dukan/setup-your-dukan/setup-your-dukan.component';
import { StoreLinkedSuccessfullyComponent } from './dukan/store-linked-successfully/store-linked-successfully.component';
import { StoreLinkingErrorComponent } from './dukan/store-linking-error/store-linking-error.component';
import { OldStoresComponent } from './old-stores/old-stores.component';

// TODO: Display success/failure popups on store integration

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  standalone: true,
  imports: [
    OldStoresComponent,
    ApiKeysIntegrationComponent,
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    CommonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
  ],
  providers: [StoresPresenter],
})
export class StoresComponent
  extends BaseComponent<StoresPresenter, StoresViewState, StoresViewEvents>
  implements AfterViewInit
{
  revampedStoresEnabled = false;

  wooCommerceStoreSettingsEnabled = false;

  activeCourseIndex: number | null = null;

  activeQuestionIndex: number | null = null;

  contentQuestionHeights: number[] = [];

  contentCourseHeights: number[] = [];

  public currentCountryLinkedStore: UserStoreModel;

  stores = [];

  receivedStoreUrl: string;

  selectedCountry: CountryService;

  public sanitizedUrl: SafeUrl;

  public dukanShop: DukanShopModel;

  public isLoading = true;

  public isUserEligibleToDukan = true;

  public categories: TreeNode<BaseCategoryModel>[];

  public selectCategoriesForm: UntypedFormGroup;

  public formIsSubmitted = false;

  @ViewChildren('accordionQuestionsContent') contentQuestionElements!: QueryList<ElementRef>;

  @ViewChildren('accordionCourseContent') contentCourseElements!: QueryList<ElementRef>;

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _reactivateShopUseCase: ReactivateDukanShopUseCase,
    public presenter: StoresPresenter,
    private _linkMerchantStoreUseCase: LinkMerchantStoreUseCase,
    private _dialog: MatDialog,
    private _modal: MatDialog,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _sanitizer: DomSanitizer,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
    private fb: FormBuilder,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,

    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    super();
    this._getFeatureFlagUseCase.execute(REVAMPED_STORES).subscribe((flag) => {
      this.revampedStoresEnabled =
        flag && this._checkUserFeatureExistsUseCase.execute(STORES_REVAMP_USER_FEATURE);

      this.wooCommerceStoreSettingsEnabled =
        flag && this._checkUserFeatureExistsUseCase.execute(WOO_COMMERCE_STORE_IMPORT_USER_FEATURE);
      this.selectedCountry = country;
    });

    this.selectCategoriesForm = this.fb.group({
      selectCategory: ['', Validators.required],
    });
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });

    this.checkStoresIntegrationState();

    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (commercialCategoriesTree) => {
        if (commercialCategoriesTree) {
          const categoriesNodes = commercialCategoriesTree.root.children;
          this.categories = categoriesNodes;
        }
      },
    });
  }

  public onSubmit(): void {
    const selectedCategory = this.selectCategoriesForm.get('selectCategory')?.value;
  }

  protected checkStoresIntegrationState(): void {
    const params = queryString.all();
    // now clear the query string
    queryString.clear();

    if (params.provider === ZID_PROVIDER_NAME && params.code === 'undefined') {
      window.location.href = getStoreRedirectUri(ZID_PROVIDER_NAME)!;

      return;
    }

    const success = queryString.get('success');

    if (success) {
      return this.displayWooCommerceIntegrationState();
    }

    if (!params.provider) return;

    const linkedStore = this.viewState.userStores.find(
      (store) => store.provider === params.provider,
    );

    if (!linkedStore) return;

    this.isLoading = true;

    this._logMixpanelEventUseCase.execute({
      eventName: 'click_link_store_button',
      payload: { provider: params.provider },
    });

    const location = `https://${window.location.hostname}`;

    this._linkMerchantStoreUseCase
      .execute({
        authCode: params.code,
        provider: params.provider,
        redirectUri: location + linkedStore!.redirectUri,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.getUserStores();
          this._logMixpanelEventUseCase.execute({
            eventName: 'click_link_store_successful_message',
            payload: {
              provider: params.provider,
              storeId: this.viewState.currentCountryLinkedStore?.storeId,
            },
          });
          this._dialog.open(StoreLinkedSuccessfullyComponent, {
            width: '450px',
            panelClass: 'linked-store-dialog',
            direction: this.direction,
          });
        },
        error: (err) => {
          this._logMixpanelEventUseCase.execute({
            eventName: 'click_link_store_failure_message',
            payload: { error: err.error.message, provider: params.provider },
          });
          this._dialog.open(StoreLinkingErrorComponent, {
            width: '450px',
            panelClass: 'linked-store-dialog',
            data: {
              provider: params.provider,
            },
          });
        },
      });
  }

  displayWooCommerceIntegrationState(): void {
    const success = queryString.get('success');

    if (!success) return;

    if (success === '1') {
      this.openSuccessWooCommerceModal();
    } else if (success === '0') {
      this.openFailureWooCommerceModal();
    }

    // now clear the query string
    queryString.clear();
  }

  getUserStores(): void {
    this.presenter.getUserStores();
  }

  public isDisplayingDukanStore(): boolean {
    return this.viewState.selectedStore.provider === DUKAN_PROVIDER_NAME;
  }

  onUnlinkStoreClicked(store: StoreModel): void {
    const dialogRef = this._dialog.open(UnlinkStorePopUpComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: {
        provider: store.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.getUserStores();
      },
    });
    this._logMixpanelEventUseCase.execute({
      eventName: 'click_unlink_store_button',
      payload: {
        provider: store.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
  }

  ngAfterViewInit(): void {
    this.contentQuestionHeights = this.contentQuestionElements.map(
      (el) => el.nativeElement.scrollHeight,
    );

    this.contentCourseHeights = this.contentCourseElements.map(
      (el) => el.nativeElement.scrollHeight,
    );
  }

  openSuccessWooCommerceModal(): void {
    this._modal.open(WooCommerceSuccessModalComponent, {
      direction: this.direction,
      width: '485px',
    });
  }

  openFailureWooCommerceModal(): void {
    this._modal.open(WooCommerceFailureModalComponent, {
      direction: this.direction,
      width: '485px',
    });
  }

  public openVideoDialog(): void {
    this._dialog.open(YoucanLinkVideoComponent, {
      width: '800px',
    });
    this._logMixpanelEventUseCase.execute({ eventName: 'you_can_video_button_clicked' });
  }

  toggleCourseAccordion(index: number): void {
    this.activeCourseIndex = this.activeCourseIndex === index ? null : index;

    this._logMixpanelEventUseCase.execute({
      eventName: 'click_course_accordion',
      payload: {
        provider: this.viewState.selectedStore.provider,
      },
    });
  }

  toggleQuestionAccordion(index: number): void {
    this.activeQuestionIndex = this.activeQuestionIndex === index ? null : index;
  }

  onStoreSelected(store: NewStoreModel): void {
    this.presenter.emitViewEvent({
      type: 'SelectionChanged',
      store,
    });

    this.viewState.selectedStore = store;
  }

  protected openWoocommerceModal(): void {
    const insertLinkModal = this._dialog.open(WooCommerceModalComponent, {
      direction: this.direction,
      panelClass: 'linked-store-dialog',
      width: '524px',
    });

    insertLinkModal.afterClosed().subscribe((res) => {
      if (res === true) {
        window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
      }
    });
  }

  protected openYoucanModal(): void {
    const dialogRef = this._dialog.open(YoucanCheckoutPopUpComponent, {
      width: '900px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        window.location.href = getStoreRedirectUri(this.viewState.selectedStore.provider!) || '';
      }
    });
  }

  protected openZidModal(): void {
    window.location.href = getStoreRedirectUri(this.viewState.selectedStore.provider!) || '';
  }

  protected setupDukanDialog(): void {
    const dialogRef = this._dialog.open(SetupYourDukanComponent, {
      width: '700px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.dukanShop = res;
        this.sanitizedUrl = this._sanitizer.bypassSecurityTrustUrl(this.dukanShop?.url);
        this.presenter.updateDukanStoreData(res);
        if (res?.state === StateStatuses.ACTIVE) {
          this._dialog.open(DukanSuccessfullyCreatedComponent, {
            width: '700px',
            panelClass: 'linked-store-dialog',
            direction: this.direction,
            data: {
              url: res.url,
              reactivated: false,
            },
          });
        }
      },
    });
  }

  public openStoreIntegrationModal(): void {
    if (!this.viewState.selectedStore.provider) return;

    const integrationsModalsOpeners = {
      [WOOCOMMERCE_PROVIDER_NAME]: this.openWoocommerceModal.bind(this),
      [YOUCAN_PROVIDER_NAME]: this.openYoucanModal.bind(this),
      [ZID_PROVIDER_NAME]: this.openZidModal.bind(this),
      [DUKAN_PROVIDER_NAME]: this.setupDukanDialog.bind(this),
    };

    const method =
      integrationsModalsOpeners[
        this.viewState.selectedStore.provider as keyof typeof integrationsModalsOpeners
      ];

    if (method) {
      method();
    }
  }

  public storeUrlShouldBeDisplayed(): boolean {
    if (this.viewState.selectedStore.provider === DUKAN_PROVIDER_NAME) {
      if (
        this.viewState.dukanLinkedStore &&
        this.viewState.dukanLinkedStore?.state === StateStatuses.ACTIVE
      ) {
        return true;
      }

      return false;
    }

    // Youcan does not provide store url to be displayed, so we will mark it always as false to not be displayed
    if (this.viewState.selectedStore.provider === YOUCAN_PROVIDER_NAME) {
      return false;
    }

    if (
      this.viewState.selectedStore.provider ===
        this.viewState.currentCountryLinkedStore?.provider &&
      // store id = store url
      this.viewState.currentCountryLinkedStore?.storeId
    ) {
      return true;
    }

    return false;
  }

  public shouldConnectToStore(): boolean {
    const currentSelectedStore = this.viewState.selectedStore;

    if (currentSelectedStore.provider !== DUKAN_PROVIDER_NAME) {
      return this.viewState.currentCountryLinkedStore?.provider !== currentSelectedStore.provider;
    }

    // now if it is dukan, we have 3 scenarios
    // 1. dukan is not connected
    // 2. dukan is connected and is active
    // 3. dukan is connected and is inactive
    const dukanStore = this.viewState.dukanLinkedStore;

    if (!dukanStore) {
      return true;
    }

    return false;
  }

  public shouldDisableStoreConnection(): boolean {
    if (this.viewState.selectedStore.provider === DUKAN_PROVIDER_NAME) {
      return false;
    }

    if (!this.viewState.currentCountryLinkedStore?.provider) return false;

    return (
      this.viewState.currentCountryLinkedStore?.provider !== this.viewState.selectedStore.provider
    );
  }

  public dukanStoreIsLinkedAndInActive(): boolean {
    if (this.viewState.selectedStore.provider !== DUKAN_PROVIDER_NAME) return false;

    return this.viewState.dukanLinkedStore?.state === StateStatuses.INACTIVE;
  }

  public isStoreLinked(): boolean {
    const { selectedStore } = this.viewState;

    if (selectedStore.provider === DUKAN_PROVIDER_NAME) {
      const dukanStore = this.viewState.dukanLinkedStore;
      return dukanStore?.state === StateStatuses.ACTIVE;
    }

    const currentLinkedStore = this.viewState.currentCountryLinkedStore;

    if (currentLinkedStore?.provider !== selectedStore.provider) return false;

    if (!currentLinkedStore) return false;

    if (currentLinkedStore.storeId) return true;

    return false;
  }

  public dukanShopIsActive(): boolean {
    return (
      this.viewState.dukanLinkedStore?.provider === this.viewState.selectedStore.provider &&
      this.viewState.dukanLinkedStore?.state === StateStatuses.ACTIVE
    );
  }

  public reactiveDukanStore(): void {
    this._reactivateShopUseCase.execute().subscribe({
      next: (store) => {
        this.presenter.updateDukanStoreData(store);
        this._dialog.open(DukanSuccessfullyCreatedComponent, {
          width: '700px',
          panelClass: 'linked-store-dialog',
          direction: this.direction,
          data: {
            url: store.url,
            reactivated: true,
          },
        });
      },
      error: (error) => {
        toastError(parseError(error));
      },
    });
  }

  public storeUrl(): string {
    let url = '';

    if (
      this.viewState.selectedStore?.provider === this.viewState.currentCountryLinkedStore?.provider
    ) {
      url = this.viewState.currentCountryLinkedStore?.storeId || '';
    }

    if (url && !url.startsWith('https')) {
      url = `https://${url}`;
    }

    if (
      this.viewState.selectedStore.provider === DUKAN_PROVIDER_NAME &&
      this.viewState.dukanLinkedStore?.storeId
    ) {
      url = this.viewState.dukanLinkedStore?.storeId;
    }

    return url;
  }

  public displayHowToConnectStore(): void {
    this._dialog.open(YoucanLinkVideoComponent, {
      width: '800px',
      data: {
        url: this.viewState.selectedStore.howToLinkVideo,
      },
    });

    this._logMixpanelEventUseCase.execute({
      eventName: 'store_watch_video_button_clicked',
      payload: {
        provider: this.viewState.selectedStore.provider,
      },
    });
  }

  public currentStoreHasAPIKey(): boolean {
    return this.viewState.selectedStore.provider === EASY_ORDERS_PROVIDER_NAME;
  }

  public storeIsEnabled(store: NewStoreModel): boolean {
    if (store.provider === DUKAN_PROVIDER_NAME) {
      return this.isUserEligibleToDukan;
    }

    return true;
  }

  public disconnectStore(): void {
    const dialogRef = this._dialog.open(UnlinkStorePopUpComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: {
        provider: this.viewState.currentCountryLinkedStore?.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (unlinked: boolean) => {
        if (unlinked) {
          this.getUserStores();

          this.viewState.currentCountryLinkedStore = undefined;
        }
      },
    });

    this._logMixpanelEventUseCase.execute({
      eventName: 'click_unlink_store_button',
      payload: {
        provider: this.viewState.currentCountryLinkedStore?.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
  }

  public startStoreIntegration(): void {
    if (this.viewState.selectedStore.name === YOUCAN_PROVIDER_NAME) {
      const dialogRef = this._dialog.open(YoucanCheckoutPopUpComponent, {
        width: '900px',
        panelClass: 'linked-store-dialog',
        direction: this.direction,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
        }
      });
    }

    if (this.viewState.selectedStore.provider === WOOCOMMERCE_PROVIDER_NAME) {
      const insertLinkModal = this._dialog.open(WooCommerceModalComponent, {
        direction: this.direction,
        panelClass: 'linked-store-dialog',
        width: '524px',
      });

      insertLinkModal.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
        }
      });
    } else {
      window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
    }
  }
}
